/*
 * @Date: 2023-12-10 02:54:58
 * @LastEditors: Sneed
 * @LastEditTime: 2023-12-23 16:55:13
 * @FilePath: /belleson-frontend/src/store/index.js
 */
import AppStore from './appStore.js';
import DictStore from './DictStore.js';
import { autorun } from 'mobx';
let appStore = new AppStore();
let dictStore = new DictStore()
const stores = {
  appStore,
  dictStore
//   eventStore,
//   rootStore: {
//     appStore
//   }
};


autorun(() => {//当mstore.total发生变化的时候会出发autorun的执行
});
/// 默认导出接口
export default stores;