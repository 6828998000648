/*
 * @Date: 2023-12-09 13:58:38
 * @LastEditors: Sneed
 * @LastEditTime: 2024-04-06 14:19:22
 * @FilePath: /belleson-frontend/src/routers/router.js
 */
import { Navigate } from 'react-router-dom'
import loadable from '@loadable/component'
const loadableCom = name => loadable(() => import(/* webpackChunkName: "[request]" */`../views/${name}`))
const routers = [
  {
    path: '/',
    render: () => loadableCom('Login/index'),
    auth: 'all'
  },
  {
    path: 'home',
    render: () => loadableCom('Index/index'),
    title: '首页',
    children: [
      {
        path: '',
        render: () => loadableCom('Index/Home/index'),
        exact: true,
      },
      {
        path: 'user',
        render: () => loadableCom('Index/User/index'),
        title: '用户中心',
        exact: true,
      },
      {
        path: 'custom',
        render: () => loadableCom('Index/Custom/index'),
        title: '客户中心',
        exact: true,
      },
      {
        path: 'busi',
        title: '运营中心',
        render: () => loadableCom('Index/Operate/index'),
        exact: true,
      },
      {
        path: 'direct',
        render: () => loadableCom('Index/Direct/index'),
        title: '会员卡统计',
        exact: true,
      },
      {
        path: 'detail/:pagetype',
        render: () => loadableCom('Index/Detail/index'),
        title: '新增',
      },
      {
        path: 'vip',
        title: '会员中心',
        render: () => loadableCom('Index/Vip/index'),
        children: [
          {
            path: 'add',
            title: '新增',
            render: () => loadableCom('Index/Vip/add'),
          }
        ]
      },
      {
        path: 'warehouse',
        render: () => loadableCom('warehouse'),
        title: '轻养舱',
        children: [
          {
            path: 'add',
            title: '新增',
            render: () => loadableCom('warehouse/add'),
            children: [{
              path: 'device',
              title: '新增设备',
              render: () => loadableCom('warehouse/adddevice'),
            }]
          }
        ]
      },
      {
        path: 'inventory',
        render: () => loadableCom('Inventory'),
        title: '清单管理',
        children: [
          {
            path: 'add',
            title: '新增',
            render: () => loadableCom('Inventory/add'),
          }
        ]
      },
      {
        path: 'workorder',
        render: () => loadableCom('View'),
        title: '智能工单',
        children: [
          {
            path: '',
            title: '',
            exact: true,
            render: () => loadableCom('workorder'),
          },
          {
            path: 'view',
            title: '详情',
            render: () => loadableCom('View'),
            children: [
              {
                path: '',
                title: '',
                exact: true,
                render: () => loadableCom('workorder/view'),
              },
            ]
          }
        ]
      },
      {
        path: 'orderMgt',
        render: () => loadableCom('View'),
        title: '订单管理',
        children: [
          {
            path: '',
            title: '',
            exact: true,
            render: () => loadableCom('orderMgt'),
          },
          {
            path: 'view',
            title: '详情',
            render: () => loadableCom('View'),
            children: [
              {
                path: '',
                title: '',
                exact: true,
                render: () => loadableCom('orderMgt/detail'),
              },
            ]
          }
        ]
      },
      {
        path: 'reservation',
        render: () => loadableCom('View'),
        title: '预约信息管理',
        children: [
          {
            path: '',
            title: '',
            exact: true,
            render: () => loadableCom('reservation'),
          },
        ]
      },
      {
        path: 'websiteuser',
        render: () => loadableCom('View'),
        title: '官网用户管理',
        children: [
          {
            path: '',
            title: '',
            exact: true,
            render: () => loadableCom('websiteuser'),
          },
        ]
      },
      {
        path: 'help',
        title: '帮助中心',
        render: () => loadableCom('Index/help/index'),
      },
    ]
  },
  {
    path: '*',
    element: <Navigate to="/home" />
  }
]

function format(data, props, navs) {
  return data.map(item => {
    let nav = [...navs]
    if (item.title) {
      let title = item.title
      nav.push({
        title,
        path: item.path
      })
    }
    if (!props?.token && item.auth !== 'all') {
      item.element = <Navigate to="/" />
    } else if (item.render) {
      const Comp = item.render()
      item.element = <Comp {...props} navs={nav} />
    }
    if (item?.children?.length > 0) {
      item.children = format(item.children, props, nav)
    }
    return item
  })
}

export default function routersNew(props) {
  const routersNew = format(routers, props, [])
  return routersNew
}