/*
 * @Date: 2023-12-10 02:54:52
 * @LastEditors: Sneed
 * @LastEditTime: 2023-12-25 01:10:28
 * @FilePath: /belleson-frontend/src/store/DictStore.js
 */
import { observable, makeObservable, runInAction, toJS, flow } from 'mobx';
import {setItem, getItem} from '../utils/LocalStorage'
class DictStore {//这个store类里边定义要监听的状态，计算函数，修改函数（很类似vuex）
    constructor() {
        this.dicts = {}
        makeObservable(this, {
            dicts: observable,
            fetchOrg: flow
        })
    }
    get dictsData () {
        return toJS(this.dicts)
    }
    *fetchOrg (fetch,types) {
        let obj = {}
        while (types.length > 0) {
            const type = types.pop()
            if (this.dicts?.[type]?.length > 0) return
            try {
                const projects = yield fetch({
                    url: '/dictItem/queryItemList',
                    params: {
                        type
                    }
                }).then(res => {
                    return res?.map(item => ({
                        value: item.itemType,
                        label: item.itemValue
                    }))
                },() => {})
                obj[type] = projects
                
            } catch (error) {
                obj[type] = []
            }
        }
        runInAction(() => {
            this.dicts = {
                ...this.dicts,
                ...obj
            }
        })
    }
  };
export default DictStore;
