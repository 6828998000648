/*
 * @Date: 2023-12-12 22:15:31
 * @LastEditors: Sneed
 * @LastEditTime: 2024-01-09 23:53:10
 * @FilePath: /belleson-frontend/src/utils/http.js
 */
import axios from 'axios'
import { getItem } from './LocalStorage';
const http = axios.create({
  baseURL: process.env.NODE_ENV === 'development'? '/belleson-web' : '/belleson-web'
})
http.interceptors.request.use(function (config) {
  config.headers.Authorization = getItem('token')
  return config
}, function (error) {
  return Promise.reject(error);
});
http.interceptors.response.use(function (response) {
    if (response.data.code === "0") {
      return response.data;
    } else if (response.headers['content-type'].indexOf('application/octet-stream') > -1) {
      return response.data;
    } else {
      return Promise.reject({type: 1, data: response.data});
    }
}, function (error) {
    return Promise.reject({type: 0, data: error});
});

export const fetchFn = ({ url, params, method = 'GET', data, message, navigate,appStore,responseType }) => {
  const options = {
    url,
    method,
    params,
    data,
    timeout: 30000,
    withCredentials: true,
    headers: {
      'accept-language': window.localLang
    },
    responseType
  }
  return new Promise((resolve,reject) => {
    http(options)
      .then(res => {
        if (responseType === 'blob') {
          resolve(res)
        }
        resolve(res?.data)
      })
      .catch(({type,data}) => {
        if (type) {
          reject()
          message(`${data.message}`,'error')
        } else {
          reject()
          message(data.toString(),'error')
        }
        if(data.code === 'S0000') {
          appStore.setLoginInfo({})
          navigate('/')
        }
      }).finally(() => {
        
      })
  })
}
export const fetch  = (message,navigate,appStore) => {
  return async function () {
    let arg = [...arguments]
    let one = arg.pop()
    return fetchFn({appStore,navigate,message,...one},...arg)
  }
}
