/*
 * @Date: 2023-12-09 13:58:38
 * @LastEditors: Sneed
 * @LastEditTime: 2023-12-16 01:08:45
 * @FilePath: /belleson-frontend/src/App.js
 */
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
import React, { Component } from 'react'
import RouterContainer from './routers/index'
// import { useNavigationType } from 'react-router-dom'
// const type = useNavigationType();

// 为了兼容热更新和recoil,定义错误渲染整体样式
export default class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      hasError: false
    }
  }
  componentDidCatch(error, info) {
    console.log(`报错信息:${error}:`, error)
    console.log(`报错调用栈的组件: ${JSON.stringify(info)}:`, info)
  }
  componentDidMount () {
    // console.log('--------1ss',this.props)
  }
  render() {
    return <>
      <RouterContainer {...this.props} />
    </>
  }
}
