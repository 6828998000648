export const setItem = (key, val) => {
    localStorage.setItem(key,JSON.stringify({
        val
    }))
}
export const getItem = key => {
    let obj = localStorage.getItem(key)
    try {
        let {val} = JSON.parse(obj)
        return val
    } catch (error) {
        return ''
    }
}