/*
 * @Date: 2023-12-09 13:58:38
 * @LastEditors: Sneed
 * @LastEditTime: 2023-12-25 23:33:51
 * @FilePath: /belleson-frontend/src/routers/index.js
 */
import React, { useEffect, useState } from 'react'
import { message,App } from 'antd';
import { useRoutes, useNavigate,useLocation, useParams, useSearchParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import routes from './router'
import {fetch} from '../utils/http'
const MyApp =  props => {
  const { modal } = App.useApp();
  const {appStore} = props
  const navigate = useNavigate();
  const location = useLocation()
  const params = useParams()
  const [searchParams,setSearchParams] = useSearchParams()
  const [ele, setEle] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  // type success error warning info loading
  const $message = (content, type='info') => {
    messageApi.destroy()
    messageApi.open({
      type,
      content,
    });
  };
  const f = fetch($message,navigate,appStore)
  // const ele = routes({...props,message: $message})
  useEffect(() => {
    if (props.appStore?.token) {
      if (location.pathname === '/') {
        navigate('/home')
      } else{
        navigate(location.pathname)
      }
    }
    setEle(routes({...props,params,modal,message: $message,fetch: f,token: props.appStore?.token}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appStore?.token])
  useEffect(() => {
    // 刷新时保持参数
    sessionStorage.setItem('router-searchParams', searchParams)
  }, [searchParams,params])
  useEffect(() => {
    // 刷新时保持参数
    setSearchParams(sessionStorage.getItem('router-searchParams'))
  }, [])
  return (
    <>
      {contextHolder}
      {useRoutes(ele)}
    </>
  )
}
export default inject('appStore')(observer(MyApp))
