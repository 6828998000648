/*
 * @Date: 2023-12-09 13:58:38
 * @LastEditors: Sneed
 * @LastEditTime: 2024-01-08 21:22:09
 * @FilePath: /belleson-frontend/src/index.js
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import MyApp from './App';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider,App } from 'antd';
import stores from './store';
import reportWebVitals from './reportWebVitals';
import './assets/style/style.less'
import dayjs from 'dayjs';
// import 'lib-flexible';
import 'dayjs/locale/zh-cn';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider {...stores}>
    <HashRouter>
      {/* <React.StrictMode> */}
        <ConfigProvider locale={zhCN} theme={{
          // cssVar: { key: 'app' },
          token: {
            borderRadius: '4px',
            colorPrimary: '#68AB7E',
            colorBorder: 'transparent',
            colorBgContainer: '#F8F8F8',
            colorTextDisabled: '#000',
          },
          components: {
            Modal: {
              colorPrimary: '#68AB7E',
            },
            Table: {
              headerBorderRadius: 0,
              colorBgContainer: '#FFF',
              fontSize: (14/144) + 'rem'
            },
            Button : {
              borderRadius: 2,
            },
            Input: {
              borderRadius: 2,
              activeBorderColor: '#F8F8F8',
              activeShadow: 'none',
              hoverBg: '#F8F8F8',
              activeBg: '#F8F8F8',
              colorTextDisabled: '#000'
            },
            Select: {
              borderRadius: 2,
              colorTextDisabled: '#000'
            },
            DatePicker: {
              borderRadius: 4,
              colorTextDisabled: '#000'
            },
            Menu: {
              darkItemBg: '#484B5B',
              darkItemSelectedColor: '#000',
              darkItemSelectedBg: '#fff',
              borderRadius: 10,
              itemPaddingInline: 20,
            },
            Pagination: {
              colorPrimary: '#FFF',
              itemActiveBg: '#68AB7E',
              colorBgTextActive: '#FFF',
              // itemBg: '#F8F8F8'
            }
          },
        }}>
          <App style={{width: '100%',height: '100%'}}>
            <MyApp dayjs={dayjs} />
          </App>
        </ConfigProvider>
      {/* </React.StrictMode> */}
    </HashRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// 按照设计稿1440 去动态设置 大于设计稿的 按照设计稿展示
const baseSize = 144
let scale = window.screen.width / 1440;
if (scale > 1) {
  scale = 1
}
function setRem() {
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + "px"; 
} 
setRem(); 
window.onresize = function () { setRem(); };

