/*
 * @Date: 2023-12-10 02:54:52
 * @LastEditors: Sneed
 * @LastEditTime: 2024-01-09 01:55:00
 * @FilePath: /belleson-frontend/src/store/appStore.js
 */
import { observable, makeObservable, action, toJS } from 'mobx';
import {setItem, getItem} from '../utils/LocalStorage'
class appStore {//这个store类里边定义要监听的状态，计算函数，修改函数（很类似vuex）
    constructor() {
        this.userName = localStorage.getItem('userName'); // 用户名
        this.navs = '' // 导航

        this.token = getItem('token')
        this.menuList = getItem('menuList') || []
        this.organizationId = getItem('organizationId')
        this.organizationType = getItem('organizationType')
        this.isAdmin = getItem('isAdmin')
        this.roles = getItem('roles')
        this.logo = getItem('logo')
        this.functionList = getItem('functionList')
        makeObservable(this, {
            userName: observable,
            navs: observable,
            token: observable,
            menuList: observable,
            organizationId: observable,
            organizationType: observable,
            isAdmin: observable,
            roles: observable,
            functionList: observable,
            changeNavs: action,
            setLoginInfo: action
        })
    }
    get navsLen () {
        return this.navs?.length
    }
    setLoginInfo ({token = '',menuList = [], organizationId = '',organizationType='',isAdmin='', roles= [], logo = '',functionList = []}) {
        this.token = token
        setItem('token', token)
        this.menuList = menuList
        setItem('menuList', menuList)
        this.organizationId = organizationId
        setItem('organizationId', organizationId)
        this.organizationType = organizationType
        setItem('organizationType', organizationType)
        this.isAdmin = isAdmin
        setItem('isAdmin', isAdmin)
        this.roles = roles
        setItem('roles', roles)
        this.logo = logo
        setItem('logo', logo)
        this.functionList = functionList || []
        setItem('functionList', functionList)
    }
    changeNavs (data) {
        this.navs = data
    }
    get btnList () {
        return toJS(this.functionList)?.map(item => item.nameEn)
    }
    get role () {
        return toJS(this.roles)
    }
  };
export default appStore;